//TOP SVG IMAGE HOVER ANIMATION
SVGInject.setOptions({
  onFail: function (img, svg) {
    // if injection fails show the img element
    img.classList.remove('injectable');
  }
});

document.addEventListener('DOMContentLoaded', function () {
  // inject all img elements with class name `injectable`
  SVGInject(document.querySelectorAll('img.injectable'), {
    onAllFinish: function () {
      // the SVG injection has finished for all three images
    }
  });
});

//SIDEBAR Navigation Menu
$(document).ready(function () {
  $('#sidebar ul li ul').each(function (index, e) {
    var count = $(e).find('li').length;
    var content = '<i class="fa fa-angle-right cnt" aria-hidden="true"></i>';
    $(e).closest('li').children('a').append(content);
  });

  $('#sidebar ul li a').click(function () {
    var checkElement = $(this).next();

    if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
      $('#sidebar ul ul:visible').slideUp('normal');
      checkElement.slideDown('normal');
      setTimeout(function () {
        $(".nano").nanoScroller({
          alwaysVisible: true
        });
      }, 200);
    }
    if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
      checkElement.slideDown('normal');
      setTimeout(function () {
        $(".nano").nanoScroller({
          alwaysVisible: true
        });
      }, 300);
    }

  });



});

//NAVIGATION HOVER IMAGE
// $(function () {
// $("#sidebar ul li:not(.active) a").mouseover(function () {
// $(this).find('img').attr("src", $(this).find('img').attr("src").replace(".svg", "_act.svg"));
// $(".test").text($(this).attr("src"));
// }).mouseout(function () {
// $(this).find('img').attr("src", $(this).find('img').attr("src").replace("_act.svg", ".svg"));
// $(".test").text($(this).attr("src"));
// });

// if ($(".menu-items li").hasClass("active")) {
// $('.menu-items').find('li.active').find('img').attr("src", $('.menu-items').find('li.active').find('img').attr("src").replace(".svg", "_act.svg"));
// }
// });

//NAVIGATION SIDEBAR
$(function () {
  $('#sidebarCollapse').click(function () {
    $(this).toggleClass('open');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
    $('.right-sidebar').removeClass('show-search');
  });

  $('.search-toggle').click(function () {
    $('.right-sidebar').toggleClass('show-search');
    $(".nano").nanoScroller({
      alwaysVisible: true
    });
  });
});

setTimeout(function () {
  $(".nano").nanoScroller({
    alwaysVisible: true
  });
}, 500);

//ALPHA LIST FOR SONG PAGE
// $(function () {
// var previous = "";
// $(".songs-details div.nav-list").each(function () {
// var str = $(this).attr('class');
// var current = str.split(" ");
// current = current[1][0];
// if (current != previous) {
// $(this).attr("id", "first_letter_" + current);
// previous = current;
// $("#index").append("<li><a href='#first_letter_" + current + "'>" + current + "</a></li>");
// }
// });
// });



//SET HEIGHT FOR MAIN DIV
$(function () {
  var $header = $('header');
  var $player = $('.player-main');
  var $content = $('.dashboard-content');
  var $alpha = $('#index');
  var $alphanumber = $('#index li');

  var $window = $(window).on('resize', function () {
    var height = $(this).height() - $header.height() - $player.height() - 1;

    $content.height(height);
    $alpha.height($content.height() - 60);
    $alphanumber.height($alpha.height() / 26);
  }).trigger('resize');


});

//MIDDLE SECTION WIDTH
// $(function () {
//   var $leftside = $('#sidebar');
//   var $rightside = $('.right-sidebar');
//   var $content2 = $('.mid-main');
//   var $window = $(window).on('resize', function () {

//     setTimeout(function () {

//       var width = $(this).width() - $leftside.width() - $rightside.width() - 2;
//       $content2.width(width);

//     }, 500);
//   }).trigger('resize');


// });








$(function () {
  $("#slider-range-max").slider({
    range: "max",
    min: 50,
    max: 100,
    value: 1,
    slide: function (event, ui) {
      $("#amount").val(ui.value);
    }
  });
  $("#amount").val($("#slider-range-max").slider("value"));
});


$(function () {
  $("#master").slider({
    value: 20,
    orientation: "horizontal",
    range: "min",
    animate: true
  });
});

//Player Icon
$("#play-pause").click(function () {
  $(this).find('svg').toggle();
});

$("#volume-pm").click(function () {
  $(this).find('svg').toggle();
});


//Half SCROLL
$(function () {
  var $header = $('header');
  var $player = $('.player-main');
  var $profile = $('.edit-profile');
  var $content3 = $('#parent');
  var $window = $(window).on('resize', function () {
    var height = $(this).height() - $header.height() - $player.height() - $profile.height() - 80;
    $content3.height(height);
  }).trigger('resize');
});

//MIDDLE SECTION WIDTH
/* $(function () {
  var $leftside = $('#sidebar');
  var $rightside = $('.right-sidebar');
  var $content2 = $('.mid-main');
  var $window = $(window).on('resize', function () {
    var width = $(this).width() - $leftside.width() - $rightside.width();
    $content2.width(width);
  }).trigger('resize');
}); */

//Filter DIV
var $btns = $('.tab-link').click(function () {
  if (this.id == 'all') {
    $('#parent > .nano-content .box').fadeIn(450);
    $(".nano").nanoScroller({
      alwaysVisible: true
    });
  } else {
    var $el = $('.' + this.id).fadeIn(450);
    $('#parent > .nano-content .box').not($el).hide();
    $(".nano").nanoScroller({
      alwaysVisible: true
    });
  }
  $btns.removeClass('active');
  $(this).addClass('active');
})

//Filter DIV Search
var $btns = $('.tab-link1').click(function () {
  if (this.id == 'all-result') {
    $('#search-tab > .search-box').fadeIn(450);
    //$(".nano").nanoScroller({ alwaysVisible: true });
  } else {
    var $el = $('.' + this.id).fadeIn(450);
    $('#search-tab > .search-box').not($el).hide();
    //$(".nano").nanoScroller({ alwaysVisible: true });
  }
  $btns.removeClass('active');
  $(this).addClass('active');
})

//Players Event 
//===================================================
// $(".clsPlayerPlay").click(function () {
// document.getElementById("audioPlayer").play();
// });

$(document).on('click', '.clsPlayerPlay', function () {
  let userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  if (userDetails.subscriptionStatus == "True") {
    document.getElementById("audioPlayer").play();
  } else if (userDetails.subscriptionStatus == null) {
    document.getElementById("audioPlayer").play();
  } else if (userDetails.artistId != null) {
    document.getElementById("audioPlayer").play();
  }

})


$(document).on('click', '.clsPlayerPause', function () {
  document.getElementById("audioPlayer").pause();
})



var owl = $('.owl-carousel');
owl.owlCarousel();
// Listen to owl events:
owl.on('dragged.owl.carousel', function (event) {
  setTimeout(() => {
    document.getElementById("audioPlayer").play();
  }, 1000);

})

//scroll function in discover page
//   $("index").click(function() {
//     var target = document.getElementById("index");
//     target.parentNode.scrollIntoView = target.offsetTop;    
// });

//discover  
//$('#index li a').click(function () {
$(document).on("click", '#index li a', function () {
  var alph = $(this).text();
  // $('.clslistalpha').hide();
  // var classalpha = '.' + alph.trim() + '-alphanav';
  // $(classalpha).show();
  var target = '';
  target = document.querySelector('#first_letter_' + alph.trim());
  target.scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
});

//discover1
// $(document).on('click','#index li a',function(){
//   // $('#index li a').click(function () {
//   var alph = $(this).text();
//   // $('.clslistalpha').hide();
//   // var classalpha = '.' + alph.trim() + '-alphanav';
//   // $(classalpha).show();
//   var target = '';
//   target = document.querySelector('#first_letter_' + alph.trim());
//   target.scrollIntoView({
//   behavior: "smooth",
//   block: "start"
//   });
//   });


//validation on login page.
//username

// function ValidateUsername() {
//     var username = document.getElementById("UserName").value;
//     var lblError = document.getElementById("lblError");
//     lblError.innerHTML = "";
//     var expr = /^[a-zA-Z0-9_]{3,10}$/;
//     if (!expr.test(username)) {
//         lblError.innerHTML = "Only Alphabets, Numbers and between 3 to 10 characters.";
//     }
// }

//password
// function ValidatePassword(){
//   var password = document.getElementById("Password").value;
//     var lblError1 = document.getElementById("lblError1");
//     lblError1.innerHTML = "";
//     var expr = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
//     if (!expr.test(password)) {
//         lblError1.innerHTML = "One Uppercase,one lowercase,one specialcharacter.";
//     }
// }
// //email
// function ValidateEmail(){
//   var email = document.getElementById("Email").value;
//   var lblError2 = document.getElementById("lblError2");
//   lblError2.innerHTML = "";
//   var expr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (!expr.test(email)) {
//       lblError2.innerHTML = "email is not valid";
//   }
// }



$(document).ready(function () {
  $('.close-cookie').on('click', function () {
    $('.cookies-modal').hide();
  });
});
